<template>
  <div class="basemap">
    <diaporama-map
      :class="{
        diaporama_map_diapo_on: isDiaporamaOpen,
        diaporama_map_diapo_off: !isDiaporamaOpen,
      }"
      :map="map"
      :photosGeoJson="photosData"
      :isOpen="isDiaporamaOpen"
      :decoration="false"
    />
    <div
      id="mapContainer"
      :class="{
        coring_map_diapo_on: isDiaporamaOpen,
        coring_map: !isDiaporamaOpen,
      }"
    >
      <div id="geocoder" class="geocoder" ref="geocoder"></div>
      <map-nav
        :map="map"
        :isTableDisplayed="false"
        :tableHeight="250"
        @styleChanged="resetLayers"
        :measuringTapeContainerId="'distance'"
        :isMapInReloading="mapIsReloading"
      ></map-nav>
      <v-btn
        depressed
        class="diaporama_btn"
        color="#f1f1f1"
        v-if="isDiaporamaButtonDisplayed"
        @click="isDiaporamaOpen = !isDiaporamaOpen"
        >{{ isDiaporamaOpen ? "Fermer diaporama" : "Afficher diaporama" }}
        <v-icon color="black">{{
          isDiaporamaOpen ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon></v-btn
      >
      <div class="bottom-left-gizmo">
        <div id="distance" class="distance-container"></div>
      </div>
      <map-filtre
        v-show="!isLoading && this.config.dashboardBtnStatus === 2"
        :selectedLayers="['Carottes_Id']"
        :layersLegend="legends"
        @filterChanged="changeFilter"
      ></map-filtre>
    </div>
  </div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import coringCampaignStatus from "../../../utils/coringCampaign";
import coreStatus from "../../../utils/core";
import DiaporamaMap from "../../map/Diaporama.map.vue";
import mapNav from "../../map/Map.nav.vue";
import store from "@/store";
import * as turf from "@turf/turf";
import MapFiltre from "../../map/Map.filtre.vue";
import filter from "../../../utils/coringCampaign/config.filter";
import MapPopup from "@/components/popup/Coring.map.popup.vue";
import Vue from "vue";

export default {
  name: "coringMap",
  components: {
    DiaporamaMap,
    mapNav,
    MapFiltre,
  },
  props: ["campaign", "cores", "enableDraw", "resultMode"],
  data() {
    return {
      accessToken:
        "pk.eyJ1IjoiYWJhaWxseTUiLCJhIjoiY2t2dGd1ZXFoMHJhODJubHl2OG5ueGo2byJ9.KC7fg_kMFLW-FMsgoU7UPQ",
      location: null,
      map: {},
      draw: {},

      markers: [],
      markerConfig: {
        type: "marker",
        paint: {
          "marker-radius": 10,
          "marker-color": "#3887be",
        },
      },
      isDiaporamaOpen: false,
      photosData: {},
      isDiaporamaButtonDisplayed: false,
      legends: [],
      isLoading: true,
      mapIsReloading: false,
      config: {},
    };
  },
  mounted() {
    this.config = coringCampaignStatus.getCurrentStatus(this.campaign.status);
    this.mapInit();
    this.updateArea();
    this.$root.$on("deleteCoring", this.deleteCore);
    this.$root.$on("flyTo", this.flyTo);
  },
  methods: {
    calculateCenter() {
      return [this.cores[0].coordinates.lng, this.cores[0].coordinates.lat];
    },
    changeFilter(filters) {
      if (filters.length > 0) {
        const statusesToFilter = filters[0].legends
          .filter((legend) => legend.selected)
          .map((legend) => legend.valueExact);
        this.markers.forEach((marker) => {
          if (statusesToFilter.indexOf(marker.status) != -1) {
            marker.marker.getElement().style.display = "flex";
          } else {
            marker.marker.getElement().style.display = "none";
          }
        });
      }
    },
    createCore(e) {
      // this.flyTo(e.features[0].geometry.coordinates);
      this.$root.$emit("activateCoreCard", e.features[0].id);
      this.cores.push({
        id: e.features[0].id,
        number: this.cores.length
          ? this.cores[this.cores.length - 1].number + 1
          : 1,
        coordinates: {
          lat: e.features[0].geometry.coordinates[1],
          lng: e.features[0].geometry.coordinates[0],
        },
        diameter: null,
        isFullStructure: null,
        direction: null,
        note: null,
        abscissa: null,
        landmark: null,
        lateral_position_id: null,
        lateralPosition: null,
        lane_id: null,
        lane: null,
        road_id: null,
        road: null,
        structure_type_id: null,
        structureType: null,
        status: "waiting_validation_no_data",
        layers: [
          {
            id: null,
            number: null,
            type: null,
            state_id: null,
            state: null,
            material_id: null,
            material: null,
            material_category_id: null,
            material_category: null,
            note: null,
          },
        ],
        pictures: [
          {
            id: null,
            name: null,
            path: null,
          },
        ],
        corePictures: [],
      });
      this.newCoringDraw();
      this.updateArea();
    },
    createMarker(infos) {
      const marker = document.createElement("div");
      marker.style.height = "20px";
      marker.style.width = "20px";
      marker.style.borderRadius = "10px";
      let status = "";
      let popup;
      const coordinates = [infos.coordinates.lat, infos.coordinates.lng];
      if (this.resultMode) {
        const statuses = this.coreCalculateStatus(infos);

        if (
          statuses.hctStatus.status === "Mauvais" ||
          statuses.hapStatus.status === "Mauvais" ||
          statuses.amianteStatus.status === "Mauvais"
        ) {
          marker.style.backgroundColor = "red";
          status = "Mauvais";
        } else if (
          statuses.hctStatus.status === "Moyen" ||
          statuses.hapStatus.status === "Moyen" ||
          statuses.amianteStatus.status === "Moyen"
        ) {
          marker.style.backgroundColor = "yellow";
          status = "Moyen";
        } else if (
          statuses.hctStatus.status === "Bon" ||
          statuses.hapStatus.status === "Bon" ||
          statuses.amianteStatus.status === "Bon"
        ) {
          marker.style.backgroundColor = "green";
          status = "Bon";
        } else {
          marker.style.backgroundColor = "gray";
          status = "Erreur";
        }

        // Create the popup
        const MapPopupClass = Vue.extend(MapPopup);
        popup = new mapboxgl.Popup({ offset: 25 })
          .setHTML('<div id="vue-popup-content"></div>')
          .setLngLat([coordinates[1], coordinates[0]])
          .addTo(this.map);

        const popupInstance = new MapPopupClass({
          propsData: {
            coreData: infos,
            campaignId: this.campaign.id,
            ...statuses,
          },
        });

        popup._closeButton.style.top = "10px";
        popup._closeButton.style.right = "10px";
        popup._closeButton.className = "mdi mdi-close popup-close-button";
        popup._closeButton.innerHTML = "";

        popupInstance.$mount("#vue-popup-content");
        popup._update();
      } else {
        marker.style.backgroundColor = coreStatus.getCurrentStatus(
          infos.status
        ).coreColor;
      }

      marker.style.display = "flex";
      marker.style.justifyContent = "center";
      marker.style.alignItems = "center";
      marker.style.color = "white";
      marker.innerHTML = infos.number;

      if (infos.corePictures.length > 0 || this.resultMode) {
        marker.style.cursor = "pointer";
      }

      marker.addEventListener("click", (e) => {
        if (!this.resultMode) e.stopPropagation();
        let corePicturesFiltered = infos.corePictures.filter(
          (cpic) => cpic.type != "technicianHelp"
        );
        this.isDiaporamaButtonDisplayed = corePicturesFiltered.length > 0;
        if (this.isDiaporamaOpen) {
          this.isDiaporamaOpen = this.isDiaporamaButtonDisplayed;
        }
        if (this.isDiaporamaButtonDisplayed) {
          let response = {
            type: "FeatureCollection",
            name: "106",
            features: [],
          };
          response.features = corePicturesFiltered.map((coreP) => {
            return {
              type: "Feature",
              properties: {
                path: coreP.path,
              },
              geometry: {
                type: "Point",
                coordinates: infos.coordinates,
              },
            };
          });
          this.photosData = response;
        }
      });

      this.nextIndex++;

      let mapboxCoord = {
        lat: coordinates[0],
        lng: coordinates[1],
      };
      if (this.resultMode) {
        this.markers.push({
          id: infos.id,
          status: status,
          marker: new mapboxgl.Marker(marker)
            .setLngLat(mapboxCoord)
            .setPopup(popup)
            .addTo(this.map),
        });
      } else {
        this.markers.push({
          id: infos.id,
          status: status,
          marker: new mapboxgl.Marker(marker)
            .setLngLat(mapboxCoord)
            .addTo(this.map),
        });
      }
    },
    deleteCore(id) {
      this.draw.delete(id);
      this.updateArea();
    },
    flyTo(coordinates) {
      this.map.jumpTo({
        center: coordinates,
        essential: true,
        zoom: 15,
      });
    },
    newCoringDraw() {
      this.$emit("newCoreDrawn");
    },
    updateArea() {
      this.updateMarkers();
      this.$emit("input", this.cores);
    },
    mapInit() {
      let center = { lng: 2.29, lat: 48.85 };
      if (this.cores.length > 0) {
        var features = turf.points(
          this.cores.map((core) => [core.coordinates.lng, core.coordinates.lat])
        );
        center = turf.center(features).geometry.coordinates;
        this.$store.commit("campaign/set_current", {
          center: center,
        });
      }

      let mapStyle = "mapbox://styles/mapbox/streets-v11";
      if (store.state.campaign.current.mapStyle === "satellite")
        mapStyle = "mapbox://styles/mapbox/satellite-v9";

      mapboxgl.accessToken = this.accessToken;

      this.map = new mapboxgl.Map({
        container: "mapContainer",
        style: mapStyle,
        center: center,
        zoom: 12,
      });

      const draw = new MapboxDraw({
        displayControlsDefault: false,
        controls: {
          point: true,
        },
      });
      this.draw = draw;
      this.map.addControl(draw, "top-right");

      let geocoder = new MapboxGeocoder({
        accessToken: mapboxgl.accessToken,
        mapboxgl: mapboxgl,
        placeholder: this.$t("search"),
        flyTo: { duration: 0 },
      });
      this.$refs["geocoder"].appendChild(geocoder.onAdd(this.map));

      this.map.on("load", () => {
        let drawIcon = document.querySelector(".mapbox-gl-draw_point");
        drawIcon.style.display = "none";

        let startingPos = this.returnStartingPos();
        if (startingPos != false) {
          this.map.jumpTo({
            center: startingPos,
            essential: true,
            zoom: 12,
          });
        } else {
          if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition((pos) => {
              this.flyTo([pos.coords.longitude, pos.coords.latitude]);
            });
          } else {
            alert(
              "La géolocalisation n'est pas disponible sur cet appareil ou sur ce navigateur"
            );
          }
        }
        this.map.on("draw.create", this.createCore);
        this.$root.$on("deleteCore", (e) => {
          this.deleteCore(e);
        });
        this.map.on("draw.update", (e) => {
          this.updateCoordinates(e);
          this.updateArea();
        });
        this.$root.$on("jumpToCore", (e) => {
          this.flyTo(e);
        });

        this.map.getCanvas().style.cursor = "";
        if (this.resultMode) this.legends.push(filter);
        this.isLoading = false;

        // Auto zoom fitBounding -> https://jsfiddle.net/jscastro76/vjkt7wyx/16/
        let coords = [];
        this.cores.forEach((core) => {
          coords.push([core.coordinates.lng, core.coordinates.lat])
        });
        var bounds = coords.reduce(function (bounds, coord) {
          return bounds.extend(coord);
        }, new mapboxgl.LngLatBounds(coords[0], coords[0]));
        this.map.fitBounds(bounds, {
          padding: { top: 50, bottom: 50, left: 50, right: 50 },
          easing(t) {
            return t * (2 - t);
          },
        });
      });
    },
    updateMarkers() {
      this.markers.forEach(({ marker }) => {
        marker.remove();
      });
      this.markers = [];
      if (this.cores.length > 0) {
        this.cores.forEach((infos) => {
          this.createMarker(infos);
        });
      }
    },
    updateCoordinates(e) {
      const newCores = this.cores;
      for (let i = 0; i < this.cores.length; i++) {
        const coord = this.cores[i];
        if (coord.id == e.features[0].id) {
          newCores[i].coordinates = {
            lat: e.features[0].geometry.coordinates[1],
            lng: e.features[0].geometry.coordinates[0],
          };
          break;
        }
      }
      this.cores = newCores;
    },
    resetLayers() {
      this.mapIsReloading = true;
      this.map.getCanvas().style.cursor = "wait";
      setTimeout(() => {
        this.mapIsReloading = false;
        this.map.getCanvas().style.cursor = "";
      }, 1000);
    },
    returnStartingPos() {
      if (this.cores != undefined && this.cores.length > 0) {
        var features = turf.points(
          this.cores.map((core) => [core.coordinates.lng, core.coordinates.lat])
        );
        var center = turf.center(features);
        this.$store.commit("campaign/set_current", {
          center: center.geometry.coordinates,
        });
      } else {
        return false;
      }
    },
    amianteStatus(layers) {
      let status = { status: "Bon", layers: [] };
      let isFound = false;

      if (0 === layers.length) return status;

      for (let currentLayer = 0; currentLayer < layers.length; currentLayer++) {
        if (layers[currentLayer].type === "interface") continue;
        if (layers[currentLayer].results.length === 0) continue;

        if (layers[currentLayer].results.ASBESTOS !== undefined) {
          isFound = true;

          if (layers[currentLayer].results.ASBESTOS.computed.positive) {
            status.status = "Mauvais";
          }
          status.layers[currentLayer] = layers[currentLayer].results.ASBESTOS
            .computed.positive
            ? "Mauvais"
            : "Bon";
        }
      }

      if (false === isFound) {
        return (status.status = "not_found");
      }

      return status;
    },
    hapStatus(layers) {
      let status = { status: "Bon", layers: [] };
      let isFound = false;

      if (0 === layers.length) return status;

      for (let currentLayer = 0; currentLayer < layers.length; currentLayer++) {
        if (layers[currentLayer].results.PAH !== undefined) {
          isFound = true;

          let res = layers[currentLayer].results.PAH.computed;
          const sum = res.PAHSum.value;

          if (sum > 1000) status.status = "Très Mauvais";
          else if (sum > 500 && status.status !== "Très Mauvais") {
            status.status = "Mauvais";
          } else if (
            sum > 50 &&
            status.status !== "Très Mauvais" &&
            status.status !== "Mauvais"
          ) {
            status.status = "Moyen";
          }
        }
      }

      if (false === isFound) {
        return (status.status = "not_found");
      }

      return status;
    },
    hctStatus(layers) {
      let isPositive = false;
      let isFound = false;

      for (let i = 0; i < layers.length; i++) {
        let currentLayer = layers[i];
        if (currentLayer.results.TH !== undefined) {
          isFound = true;
        }
        if (
          currentLayer.results.TH !== undefined &&
          currentLayer.results.TH.computed.total.value > 500
        ) {
          isPositive = true;
        }
      }

      if (isFound === false) {
        return { status: "not_found" };
      }

      return {
        status: isPositive ? "Mauvais" : "Bon",
      };
    },
    coreCalculateStatus(core) {
      return {
        hctStatus: this.hctStatus(core.layers),
        hapStatus: this.hapStatus(core.layers),
        amianteStatus: this.amianteStatus(core.layers),
      };
    },
  },
  watch: {
    cores() {
      this.updateArea();
    },
    enableDraw() {
      let cursorContainer = document.querySelector(
        ".mapboxgl-canvas-container"
      );

      if (this.enableDraw) {
        this.draw.changeMode("draw_point");

        cursorContainer.style.cursor = "crosshair";
      } else {
        this.draw.changeMode("simple_select");
        cursorContainer.style.cursor = "";
      }
    },
    isDiaporamaOpen(newVal) {
      let canvas = this.map.getCanvas();
      if (!newVal) {
        this.isDiaporamaOpen = false;
        canvas.style.width = "100%";
      } else {
        this.isDiaporamaOpen = true;
        canvas.style.width = "100%";
      }
      setTimeout(() => this.map.resize(), 10);
    },
  },
};
</script>

<style lang="scss">
.basemap {
  width: 100%;
  height: 85vh;

  .geocoder {
    position: absolute;
    z-index: 1;
    width: 50%;
    left: 45%;
    top: 10px;
  }
  .mapboxgl-ctrl-geocoder {
    min-width: 10%;
  }
  .bottom-left-gizmo {
    position: absolute;
    bottom: 30px;
    left: 10px;
    z-index: 1;

    .distance-container > * {
      background-color: #fff;
      font-size: 16px;
      line-height: 18px;
      display: block;
      margin: 0;
      padding: 5px 10px;
      border-radius: 3px;
    }
  }

  .coring_map {
    width: 100%;
    height: 100%;
  }
  .coring_map_diapo_on {
    width: 50% !important;
    height: 85vh;
    right: 0;
    position: absolute;
  }
  .diaporama_map_diapo_on {
    width: 50%;
    height: 85vh;
    left: 0;
    position: absolute;
    z-index: 1;
  }
  .diaporama_map_diapo_off {
    display: none;
  }
  .diaporama_btn {
    font-family: "Roboto";
    transform: rotate(-90deg);
    position: absolute;
    left: 0px;
    top: 65%;
    z-index: 1;
    transform-origin: 0% 0;
  }
}
</style>
