const filter = {
  id: "Carottes_Id",
  name: "Carottes",
  legends: [
    {
      id: 1,
      min: null,
      max: null,
      excludeMin: false,
      excludeMax: false,
      color: "red",
      valueExact: "Mauvais",
      label: "Pollué",
      position: 1,
      selected: true,
    },
    {
      id: 2,
      min: null,
      max: null,
      excludeMin: false,
      excludeMax: false,
      color: "yellow",
      valueExact: "Moyen",
      label: "Vigilance",
      position: 2,
      selected: true,
    },
    {
      id: 3,
      min: null,
      max: null,
      excludeMin: false,
      excludeMax: false,
      color: "green",
      valueExact: "Bon",
      label: "RAS",
      position: 3,
      selected: true,
    },
  ],
};

export default filter;
