<template>
  <div class="corinCampaignMap pa-4">
    <div class="d-flex justify-space-between mb-6">
      <div class="d-flex">
        <v-btn
          class="mt-5 ml-2 mr-2 m_brown"
          icon
          :to="{ name: 'project', params: { id: coringCampaign.workspace.id } }"
        >
          <v-icon large>mdi-arrow-left-bold-circle</v-icon>
        </v-btn>
        <div>
          <h1>{{ $cap(coringCampaign.name) }}</h1>
          <i>Carte</i>
        </div>
      </div>
      <div class="mt-2">
        <HighButtonSlot
          class="mr-2"
          :_to="{
            name: 'coring-campaign-dashboard',
            params: { id: $route.params.id }
          }"
        >
          <v-icon left dark> mdi-file-document-outline </v-icon>
          Tableau de bord
        </HighButtonSlot>
      </div>
    </div>

    <v-card class="map_container" outlined>
      <coring-map
        v-if="cores != undefined"
        :enableDraw="false"
        :cores="cores"
        :campaign="coringCampaign"
        :resultMode="true"
      />
    </v-card>
  </div>
</template>

<script>
import CoringMap from "../../components/coring/map/Coring.map.vue";

export default {
  components: { CoringMap },
  name: "CoringCampaignMap",
  data() {
    return {
      coringCampaign: { name: "", workspace: { id: "" } },
      cores: undefined,
      isLoading: true,
      mapIsReloading: false
    };
  },
  mounted() {
    this.$api.coringCampaigns.findById(this.$route.params.id).then(campaign => {
      this.coringCampaign = campaign;

      this.$api.campaigns.getData(this.$route.params.id, "map").then(data => {
        this.cores = data.map(c => {
          let core = { ...c };
          core.coordinates = {
            lng: c.coordinates[0],
            lat: c.coordinates[1]
          };
          core.corePictures = c.pictures;
          return core;
        });
      });
    });
  }
};
</script>

<style lang="scss">
.corinCampaignMap {
  padding: 15px;
  .coring_campaign__header {
    align-items: center;
    display: flex;
    margin-top: 14px;
  }
}
.map_container {
  border-radius: 4px !important;
  overflow: hidden;
  position: relative;
  margin: 0 8px 0 8px;
  height: 85vh;
}
.mapboxgl-canvas {
  outline: none;
}
</style>
