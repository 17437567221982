<template>
  <div class="MapPopup">
    <div class="MapPopup__textBox">
      <div class="MapPopup__otherBox">
        <h3>
          N°:
          {{ coreData.businessId }}
        </h3>
        <table>
          <tr>
            <td><span>Route:</span></td>
            <td>
              <span>{{ coreData.road.name }}</span>
            </td>
          </tr>
          <tr>
            <td><span>Sens:</span></td>
            <td>
              <span>{{ coreData.direction }}</span>
            </td>
          </tr>
          <tr>
            <td><span>PR + ABS : </span></td>
            <td>
              <span>
                {{ coreData.landmark }} + {{ coreData.abscissa || 0 }}</span
              >
            </td>
          </tr>
          <tr>
            <td><span>Epaisseur : </span></td>
            <td>
              <span> {{ depth }} cm</span>
            </td>
          </tr>
          <tr>
            <td><span>Type de structure : </span></td>
            <td>
              <span> {{ coreData.structureType }}</span>
            </td>
          </tr>
          <tr>
            <td><span>Structure complète : </span></td>
            <td>
              <span> {{ coreData.isFullStructure ? "oui" : "non" }}</span>
            </td>
          </tr>
        </table>
        <h3 class="separator">Matériaux</h3>
        <table style="width: 100%">
          <tr v-for="layer in coreData.layers" :key="layer.id">
            <td>
              <span>Couche {{ layer.number }}</span>
            </td>
            <td>
              <span>{{ fr[layer.materialCategory] }} - {{ layer.materialName }}</span>
            </td>
          </tr>
          <tr v-if="coreData.status !== 'results_acquired'">
            <td><span>Etat:</span></td>
            <td>
              <span>{{ coreData.status }}</span>
            </td>
          </tr>
        </table>
      </div>
      <div>
        <!-- HAP -->
        <div
            v-if="hapStatus.status !== 'not_found'"
          v-bind:class="{
            MapPopup__goodScore: hapStatus.status === 'Bon',
            MapPopup__mediumScore: hapStatus.status === 'Moyen',
            MapPopup__badScore: hapStatus.status === 'Mauvais',
            MapPopup__veryBadScore: hapStatus.status === 'Très Mauvais',
          }"
        >
          <p>
            <span>HAP</span>
          </p>
        </div>

        <!-- HCT -->
        <div
            v-if="hctStatus.status !== 'not_found'"
          v-bind:class="{
            MapPopup__goodScore: hctStatus.status === 'Bon',
            MapPopup__badScore: hctStatus.status === 'Mauvais',
          }"
        >
          <p>
            <span>HCT</span>
          </p>
        </div>

        <!-- Amiante -->
        <div
            v-if="amianteStatus.status !== 'not_found'"
          v-bind:class="{
            MapPopup__goodScore: amianteStatus.status === 'Bon',
            MapPopup__badScore: amianteStatus.status === 'Mauvais',
          }"
        >
          <p>
            <span>Amiante</span>
          </p>
        </div>
      </div>
      <div class="MapPopup__btnBox">
        <HighButtonSlot class="MapPopupBtn" @click="downloadPDF"
          >Rapport PDF</HighButtonSlot
        >
      </div>
      <div class="center">
        <v-tooltip top v-for="(img, index) in images" :key="index">
          <template v-slot:activator="{ on, attrs }">
            <img
              v-on="on"
              v-bind="attrs"
              class="campaign-form__imgBox"
              target="_blank"
              :src="img"
              :style="{
                backgroundImage: `url(${img})`,
              }"
              @click="
                isTogglePoppinPicture = true;
                image = img;
              "
            />
          </template>
          <span>Cliquez pour voir <br />l'aperçu de votre image</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import fr from "@/locales/fr"

export default Vue.extend({
  props: {
    coreData: {
      required: true,
      type: Object,
    },
    campaignId: {
      required: true,
      type: String,
    },
    hctStatus: {
      required: true,
      type: Object,
    },
    hapStatus: {
      required: true,
      type: Object,
    },
    amianteStatus: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      fr: fr,
      coreMap: [],
      hap: 100,
      hct: 501,
      amiante: 0.2,
      images: [],
      isTogglePoppinPicture: false,
    };
  },
  computed: {
    depth() {
      let depth = 0;
      this.coreData.layers.forEach((layer) => {
        depth += layer.thickness;
      });

      return depth;
    },
  },
  mounted() {
    this.coreMap = this.coreData.layers;
  },
  methods: {
    async downloadPDF() {
      const pdf = await this.$api.cores.getPdf(this.coreData.id);
      this.downloadFile(pdf, "core-" + this.coreData.businessId);
    },
    downloadFile(blob, fileName) {
      var newBlob = new Blob([blob], { type: "application/pdf" });
      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = fileName;
      window.open(link, "_blank");
    },
  },
});
</script>
<style lang="scss">
.popup-close-button {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  color: #000 !important;
  border: 0 important;
  border-radius: 0 3px 0 0 important;
  cursor: pointer;
  font-size: 30px;
  &:hover {
    color: red !important;
  }
}
.mapboxgl-popup-content {
  background: #ffffff;
  color: #000;
  border-radius: 10px;
  top: 0px;

  .MapPopup {
    width: 250px;

    h3 {
      margin-bottom: 10px;

      &.separator {
        border-top: 1px solid rgba(68, 68, 68, 0.562);
        width: 100%;
      }
    }

    &__goodScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        background-color: #23c015;
        color: white;
        font-weight: bold;
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__mediumScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        background-color: #e4ad35;
        color: white;
        font-weight: bold;
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__badScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        background-color: #e43d35;
        color: white;
        font-weight: bold;
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
        &__veryBadScore {
      p {
        // margin: auto;
        padding-top: 20px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 13px;
        // margin-top: 10px;
        margin-bottom: 0px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        padding: 5px;
        border-radius: 20px;
        background-color: #000000;
        color: white;
        font-weight: bold;
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__otherBox {
      border-bottom: 1px solid rgba(68, 68, 68, 0.562);
      p {
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding-left: 20px;
        padding-right: 20px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        border-radius: 20px;
        font-weight: bold;
      }
      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__etatBox {
      p {
        margin: auto;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 13px;
        padding-left: 20px;
        padding-right: 20px;
      }
      span:nth-child(1) {
        text-transform: uppercase;
        border-radius: 20px;
        font-weight: bold;
      }
    }
    &__itemBox {
      padding-top: 10px;
      p {
        width: 100%;
        flex-wrap: wrap;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;
      }

      span:nth-child(1) {
        // color: #444;
        font-weight: bold;
      }

      span:nth-child(2) {
        font-weight: bold;
      }
    }
    &__btnBox {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;
    }
    &__pannelHeader {
      margin: auto;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
    }
  }
  .center {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
</style>
