const HIDDEN = 0;
const DISABLED = 1;
const ACTIVE = 2;

const STATUS_WAITING_VALIDATION_NO_DATA = "waiting_validation_no_data";
const STATUS_WAITING_VALIDATION = "waiting_validation"; //these status do not exist in DB because they're only used on the front

const STATUS_WAITING = "waiting";
const STATUS_EXTRACTED = "extracted";
const STATUS_SENT = "sent_to_study_manager";
const STATUS_VALIDATED = "validated";
const STATUS_SENT_TO_PROVIDER = "sent_to_provider";
const STATUS_RESULTS = "results_acquired";
const STATUS_CANCELLED = "cancelled";

const core_status_array = [
  {
    status: STATUS_WAITING_VALIDATION_NO_DATA,
    formStatus: ACTIVE,
    formBtnStatus: ACTIVE,
    coreColor: "red",
    chip: {
      color: "b80000",
      icon: "mdi-close"
    },
    interfacesAndLayersFormStatus: HIDDEN
  },
  {
    status: STATUS_WAITING_VALIDATION,
    formStatus: ACTIVE,
    formBtnStatus: ACTIVE,
    coreColor: "#e89007",
    chip: {
      color: "#e89007",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: HIDDEN
  },
  {
    status: STATUS_WAITING,
    formStatus: DISABLED,
    formBtnStatus: DISABLED,
    coreColor: "green",
    chip: {
      color: "#2a7221",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: HIDDEN
  },
  {
    status: STATUS_EXTRACTED,
    formStatus: DISABLED,
    formBtnStatus: HIDDEN,
    coreColor: "green",
    chip: {
      color: "#2a7221",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: HIDDEN
  },
  {
    status: STATUS_SENT,
    formStatus: DISABLED,
    formBtnStatus: ACTIVE,
    coreColor: "green",
    chip: {
      color: "#2a7221",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: ACTIVE
  },
  {
    status: STATUS_VALIDATED,
    formStatus: DISABLED,
    formBtnStatus: HIDDEN,
    coreColor: "green",
    chip: {
      color: "#2a7221",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: DISABLED
  },
  {
    status: STATUS_SENT_TO_PROVIDER,
    formStatus: DISABLED,
    formBtnStatus: HIDDEN,
    coreColor: "green",
    chip: {
      color: "#2a7221",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: DISABLED
  },
  {
    status: STATUS_RESULTS,
    formStatus: DISABLED,
    formBtnStatus: HIDDEN,
    coreColor: "green",
    chip: {
      color: "#2a7221",
      icon: "mdi-check"
    },
    interfacesAndLayersFormStatus: DISABLED
  },
  {
    status: STATUS_CANCELLED,
    formStatus: DISABLED,
    formBtnStatus: HIDDEN,
    coreColor: "red",
    chip: {
      color: "#b80000",
      icon: "mdi-close"
    },
    interfacesAndLayersFormStatus: DISABLED
  }
];

export default {
  getCurrentStatus(coreStatus) {
    return (
      core_status_array.find(status => status.status === coreStatus) ||
      core_status_array[0]
    );
  }
};
