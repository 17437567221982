// This util file lists the coring campaign buttons labels and states depending on the campaign status id
const HIDDEN = 0;
const DISABLED = 1;
const ACTIVE = 2;

// Enum list of the status
const STATUS_AWAITING_VALIDATION = "awaiting_validation";
const STATUS_PROGRAMING_VALIDATED = "programing_validated";
const STATUS_TAKEN = "taken";
const STATUS_TAKING_VALIDATED = "taking_validated";
const STATUS_SENT_FOR_ANALYSIS = "sent_for_analysis";
const STATUS_RESULTS_OBTAINED = "results_obtained";
const STATUS_ARCHIVED = "status_archived";

const coring_campaign_status_array = [
  {
    status: STATUS_AWAITING_VALIDATION,
    coreBtn: {
      label: "+ Ajouter une carotte",
      status: ACTIVE
    },
    actionBtn: {
      label: "Valider la programmation",
      status: ACTIVE
    },
    dashboardBtnStatus : DISABLED
  },
  {
    status: STATUS_PROGRAMING_VALIDATED,
    coreBtn: {
      label: "+ Ajouter une carotte",
      status: HIDDEN
    },
    actionBtn: {
      label: "En attente des carottages",
      status: DISABLED
    },
    dashboardBtnStatus : DISABLED
  },
  {
    status: STATUS_TAKEN,
    coreBtn: {
      label: "",
      status: HIDDEN
    },
    actionBtn: {
      label: "Valider la campagne",
      status: ACTIVE
    },
    dashboardBtnStatus : DISABLED
  },
  {
    status: STATUS_TAKING_VALIDATED,
    coreBtn: {
      label: "+ Ajouter une carotte",
      status: HIDDEN
    },
    actionBtn: {
      label: "Envoyer au laboratoire",
      status: ACTIVE
    },
    dashboardBtnStatus : DISABLED
  },
  {
    status: STATUS_SENT_FOR_ANALYSIS,
    coreBtn: {
      label: "+ Ajouter une carotte",
      status: HIDDEN
    },
    actionBtn: {
      label: "Envoyé pour analyse...",
      status: DISABLED
    },
    dashboardBtnStatus : DISABLED
  },
  {
    status: STATUS_RESULTS_OBTAINED,
    coreBtn: {
      label: "+ Ajouter une carotte",
      status: HIDDEN
    },
    actionBtn: {
      label: "Résultats obtenus",
      status: HIDDEN
    },
    dashboardBtnStatus : ACTIVE
  },
  {
    status: STATUS_ARCHIVED,
    coreBtn: {
      label: "+ Ajouter une carotte",
      status: HIDDEN
    },
    actionBtn: {
      label: "Archivé",
      status: HIDDEN
    },
    dashboardBtnStatus : ACTIVE
  }
];

export default {
  getCurrentStatus(campaignStatus) {
    return (
      coring_campaign_status_array.find(
        status => status.status === campaignStatus
      ) || coring_campaign_status_array[0]
    );
  }
};
