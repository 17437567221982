var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MapPopup"},[_c('div',{staticClass:"MapPopup__textBox"},[_c('div',{staticClass:"MapPopup__otherBox"},[_c('h3',[_vm._v(" N°: "+_vm._s(_vm.coreData.businessId)+" ")]),_c('table',[_c('tr',[_vm._m(0),_c('td',[_c('span',[_vm._v(_vm._s(_vm.coreData.road.name))])])]),_c('tr',[_vm._m(1),_c('td',[_c('span',[_vm._v(_vm._s(_vm.coreData.direction))])])]),_c('tr',[_vm._m(2),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.coreData.landmark)+" + "+_vm._s(_vm.coreData.abscissa || 0))])])]),_c('tr',[_vm._m(3),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.depth)+" cm")])])]),_c('tr',[_vm._m(4),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.coreData.structureType))])])]),_c('tr',[_vm._m(5),_c('td',[_c('span',[_vm._v(" "+_vm._s(_vm.coreData.isFullStructure ? "oui" : "non"))])])])]),_c('h3',{staticClass:"separator"},[_vm._v("Matériaux")]),_c('table',{staticStyle:{"width":"100%"}},[_vm._l((_vm.coreData.layers),function(layer){return _c('tr',{key:layer.id},[_c('td',[_c('span',[_vm._v("Couche "+_vm._s(layer.number))])]),_c('td',[_c('span',[_vm._v(_vm._s(_vm.fr[layer.materialCategory])+" - "+_vm._s(layer.materialName))])])])}),(_vm.coreData.status !== 'results_acquired')?_c('tr',[_vm._m(6),_c('td',[_c('span',[_vm._v(_vm._s(_vm.coreData.status))])])]):_vm._e()],2)]),_c('div',[(_vm.hapStatus.status !== 'not_found')?_c('div',{class:{
          MapPopup__goodScore: _vm.hapStatus.status === 'Bon',
          MapPopup__mediumScore: _vm.hapStatus.status === 'Moyen',
          MapPopup__badScore: _vm.hapStatus.status === 'Mauvais',
          MapPopup__veryBadScore: _vm.hapStatus.status === 'Très Mauvais',
        }},[_vm._m(7)]):_vm._e(),(_vm.hctStatus.status !== 'not_found')?_c('div',{class:{
          MapPopup__goodScore: _vm.hctStatus.status === 'Bon',
          MapPopup__badScore: _vm.hctStatus.status === 'Mauvais',
        }},[_vm._m(8)]):_vm._e(),(_vm.amianteStatus.status !== 'not_found')?_c('div',{class:{
          MapPopup__goodScore: _vm.amianteStatus.status === 'Bon',
          MapPopup__badScore: _vm.amianteStatus.status === 'Mauvais',
        }},[_vm._m(9)]):_vm._e()]),_c('div',{staticClass:"MapPopup__btnBox"},[_c('HighButtonSlot',{staticClass:"MapPopupBtn",on:{"click":_vm.downloadPDF}},[_vm._v("Rapport PDF")])],1),_c('div',{staticClass:"center"},_vm._l((_vm.images),function(img,index){return _c('v-tooltip',{key:index,attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('img',_vm._g(_vm._b({staticClass:"campaign-form__imgBox",style:({
              backgroundImage: ("url(" + img + ")"),
            }),attrs:{"target":"_blank","src":img},on:{"click":function($event){_vm.isTogglePoppinPicture = true;
              _vm.image = img;}}},'img',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v("Cliquez pour voir "),_c('br'),_vm._v("l'aperçu de votre image")])])}),1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("Route:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("Sens:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("PR + ABS : ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("Epaisseur : ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("Type de structure : ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("Structure complète : ")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',[_c('span',[_vm._v("Etat:")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("HAP")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("HCT")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("Amiante")])])}]

export { render, staticRenderFns }